import React from 'react';
import crumbingSoon from './crumbing-soon.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1 className="visually-hidden">Crumbing soon</h1>
        <img src={crumbingSoon} className="crumbingSoon" alt="Crumbing soon" aria-hidden="true" />
      </header>
    </div>
  );
}

export default App;
